import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import {
	Timeline,
	Tag,
	Row,
	Col,
	Button,
	Tooltip,
	Card,
	Space,
	Image,
	Modal,
	Divider,
	Dropdown,
	notification,
	Upload,
} from 'antd';
import axios from 'axios';
import './index.css';
import {
	XFilled,
	EditOutlined,
	ClockCircleOutlined,
	FileOutlined,
	CheckCircleOutlined,
	FileTextOutlined,
	SmileOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
	checkModulePermissions,
	toTitleCase,
	getUserToken,
} from '../../utils/helper';
import config from '../../config';
import { Document, Page, pdfjs } from 'react-pdf'; // Import necessary components from react-pdf
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { XButtonRounded } from '../../pages/AppealLetter/AppealLetterViewStyle';
import { XButtonLike } from '../../pages/AnalyseCoverage/AnalyseCoverageStyle';
import {
	XCardsCaseBg,
	XForm,
	XModal,
	XShareButton,
} from '../../styles/GlobalStyle';
const { Dragger } = Upload;

// Dynamic Timeline
const CustomTimeline = ({
	status,
	setUpdateModalOpen,
	setRevenueCycleStatus,
	timelineData,
	revenueCycleFormData,
	setRevenueCycleFormData,
	id,
	togglePrepareLetter,
	setTogglePrepareLetter,
	setUpdatedFileList,
	setFileList,
	setSelectedOption,
	medicareCoverage,
	case_status,
	setFlag,
	setRevenueCycleStage,
	revenueCycleStage,
	patientData,
	setApproveModalOpen,
	setDownloadLoading,
}) => {
	console.log('medicareCoverage------------',medicareCoverage)
	console.log('case_status-----------------',case_status)
	const navigate = useNavigate();
	const [documentText, setDocumentText] = useState(null);
	const [statusName, setStatusName] = useState(null);
	const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
	const [pdfWidth, setPdfWidth] = useState(700); // Initial width of the modal
	const [previewImage, setPreviewImage] = useState('');
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [prepareButtonDisabled, setPrepareButtonDisabled] = useState(false);
	const [displayFaxUpload, setDisplayFaxUpload] = useState(false);
	const [displayEmailContent, setDisplayEmailContent] = useState(false);
	const [letterDownloaded, setLetterDownloaded] = useState(false);
	const [isShareModal, setIsShareModal] = useState(false);
	const items = [
		{
			key: '1',
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '160px', // Adjusted width for better display
						height: '20px',
						gap: '8px', // Added gap for spacing between icon and text
						padding: '20px auto',
					}}
					onClick={() => {
						setDisplayEmailContent(true);
					}}
				>
					<img src="/images/email-icon.svg" alt="fax" />
					Share Via Email
				</div>
			),
		},
		{
			type: 'divider',
		},
		{
			key: '2',
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '160px', // Adjusted width for better display
						height: '20px',
						gap: '8px', // Added gap for spacing between icon and text
					}}
					onClick={() => {
						setDisplayEmailContent(false);
						setIsShareModal(true);
						setDisplayFaxUpload(true);
						setLetterDownloaded(false);
					}}
				>
					<img src="/images/fax-icon.svg" alt="fax" />
					Share Via Fax
				</div>
			),
			//   icon: <SmileOutlined />,
			//   disabled: true,
		},
		{
			type: 'divider',
		},
		{
			key: '3',
			label: (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '160px', // Adjusted width for better display
						height: '20px',
						gap: '8px', // Added gap for spacing between icon and text
					}}
					onClick={() => {
						getDownloadSignedURL();
						setDownloadLoading(true);
					}}
				>
					<img src="/images/download1-icon.svg" alt="fax" />
					Download PDF
				</div>
			),
		},
	];
	const getDownloadSignedURL = async () => {
		try {
			let length = patientData.appeal_letter_logs.length - 1;
			const response = await axios.get(
				`${config.api.base_url}/settings/get-Appeal-Signed-Url?fileName=${patientData.appeal_letter_logs[length]?.url[0]?.url}`,
				{
					headers: {
						'Content-type': 'application/json; charset=UTF-8',
						Authorization: 'Bearer ' + getUserToken(),
					},
				},
			);
			let res = response.data;
			if (res.status === 1) {
				const pdfResponse = await fetch(res.data);
				const arrayBuffer = await pdfResponse.arrayBuffer();

				const blob = new Blob([arrayBuffer], {
					type: 'application/pdf',
				});
				saveAs(blob, 'Appeal-letter.pdf');
				notification.success({
					message: 'Appeal Letter downloaded',
				});
				if (displayEmailContent) {
					setDownloadLoading(false);
					setIsShareModal(true);
					setLetterDownloaded(true);
				} else {
					setDownloadLoading(false);
					setLetterDownloaded(false);
					setDisplayEmailContent(false);
					setDisplayFaxUpload(false);
					setIsShareModal(false);
				}
				setDownloadLoading(false);
			} else {
				setDownloadLoading(false);
				setLetterDownloaded(false);
				setDisplayEmailContent(false);
				setDisplayFaxUpload(false);
				setIsShareModal(false);
				notification.error({
					message: 'Unable to download Appeal Letter',
				});
			}
		} catch (error) {
			notification.success({
				message: 'Could not upload',
			});
			console.error('Error occurred:', error);
		}
	};

	const handleEmailSend = () => {
		const subject = `Case Details: ${patientData.patient_first_name} ${patientData.patient_last_name} (MRN: ${patientData.mrn})`;
		const body = `Hi,\n\nI hope this email finds you well. Below are the case details regarding denials made by ${
			patientData.insurance_company?.name || ''
		}.\n\nMRN: ${patientData.mrn}\nName: ${
			patientData.patient_first_name
		} ${patientData.patient_last_name}\nAge: ${moment(
			patientData.date_of_birth,
		).fromNow(true)},\nGender: ${
			patientData.gender ? toTitleCase(patientData.gender) : ''
		}\nDOB: ${
			patientData.date_of_birth
				? dateOfBirth(patientData.date_of_birth)
				: ''
		}\nState: ${patientData.state}\nInsurance Company: ${
			patientData.insurance_company?.name || '-'
		}\n\nClinical Summary: ${patientData.clinical_summary}.`;
		const mailtoLink = `mailto:?subject=${encodeURIComponent(
			subject,
		)}&body=${encodeURIComponent(body)}`;

		// Open the email client
		window.open(mailtoLink);
		setDisplayEmailContent(false);
		setLetterDownloaded(false);
		setDownloadLoading(false);
	};

	const dateOfBirth = (inputDateString) => {
		const date = new Date(inputDateString);

		// Extract the year, month, and day components from the Date object
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // Month is zero-based, so add 1
		const day = date.getDate();

		// Format the date as "MM-DD-YYYY" or any desired format
		return `${month.toString().padStart(2, '0')}-${day
			.toString()
			.padStart(2, '0')}-${year}`;
	};

	const handleShareOk = () => {
		setIsShareModal(false);
	};
	const handleShareCancel = () => {
		setIsShareModal(false);
		setDisplayFaxUpload(false);
		setDisplayEmailContent(false);
		setLetterDownloaded(false);
	};

	useEffect(() => {
		if (displayEmailContent) {
			getDownloadSignedURL();
			setDownloadLoading(true);
		}
	}, [displayEmailContent]);
	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleModalPreviewRemarks = (text, status) => {
		setDocumentText(text);
		setStatusName(status);
		showModal();
	};
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const onDocumentLoadError = (error) => {
		console.error('Error loading document:', error);
		// Handle error
	};

	// Function to update the width of the modal based on the width of the document
	const updateModalWidth = (width) => {
		setPdfWidth(width + 40);
	};

	const handlePreview = async (file) => {
		setPdfPreviewOpen(true);
		setPreviewImage(file);
	};

	const getTagColor = (filingStatus) => {
		switch (filingStatus) {
			case 'In Process':
				return '#1f9254';
			case 'At Risk':
				return '#e5aa11';
			case 'Untimely':
				return '#e53711';
			case 'Escalation':
				return 'orange';
			default:
				return 'green'; // default color
		}
	};

	const revenueStatusToStage = (revenueStatus) => {
		return revenueStatus.toLowerCase().replace(/ /g, '_');
	};

	// Modify this function to handle the enabling/disabling of the Confirm Button
	const prepareButtonStatus = () => {
		// Logic to determine if the button should be disabled based on your conditions
		if (
			checkModulePermissions('appeal-letter').sub_modules.includes(
				'confirm-letter',
			)
		) {
			setPrepareButtonDisabled(false);
		} else {
			setPrepareButtonDisabled(true);
		}
	};

	useEffect(() => {
		prepareButtonStatus();
	}, []);

	// const prepareButtonText = () => {
	// 	return patientData?.appeal_letter_sent_for_approval
	// 		? 'Approve Letter'
	// 		: 'Prepare Letter';
	// };

	const prepareButtonText = () => {
		return patientData?.appeal_letter_status === 'md_advisor_approved'
			? 'Send Letter'
			: patientData?.appeal_letter_status === 'md_advisor_sent'
			? 'Approve Letter'
			: patientData?.appeal_letter_status === 'coding_team_sent'
			? 'Verify Letter'
			: 'Prepare Letter';
	};

	// Function to generate timeline items dynamically based on timelineData
	const generateTimelineItems = () => {
		return timelineData.map((item, index) => {
			let itemClassName = '';
			let itemDot = null;
			let itemChildren = null;

			// Determine className, dot, and children based on item properties
			if (item.action_taken) {
				if (item.appeal_status == 'Rejected') {
					itemClassName = 'rejected';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-rejected.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col span={12}>
									<p>{item.revenue_status}</p>
								</Col>
								<Col span={12} className="text-end">
									<Space className="">
										{item && item.edit_denial_case && (
											<EditOutlined
												onClick={() => {
													setRevenueCycleStage(
														revenueStatusToStage(
															item.revenue_status,
														),
													);
													setRevenueCycleStatus('');
													setUpdateModalOpen([
														true,
														'',
													]);
													setRevenueCycleFormData({
														prev_update: true,
														id: id,
														reimbursed_at: '',
														appeal_status: '',
														claim_category: '',
														claim_amount: null,
														status: '',
														revenue_cycle_stage: '',
														is_claim_amount_settled:
															null,
														appeal_status_updated_at:
															'',
														revenue_cycle_updates: {
															revenue_cycle_status:
																'',
															appeal_status: '',
															remarks: '',
															url: [],
														},
													});
													setFileList([]);
													setUpdatedFileList([]);
													setSelectedOption('');
												}}
												style={{
													fontSize: '12px',
												}}
											/>
										)}
										<Tag
											className="tagRejected"
											icon={
												<XFilled
													style={{
														fontSize: '8px',
														lineHeight: '20px',
													}}
												/>
											}
										>
											Rejected
										</Tag>
									</Space>
								</Col>
							</Row>
							<div className="d-flex flex-wrap gap-3 justify-content-end">
								{item.appeal_letter && (
									<Row
										className="my-4 text-end"
										gutter={[30, 30]}
									>
										<Col xs={24}>
											{Array.isArray(
												item.appeal_letter,
											) &&
												item.appeal_letter.map(
													(item, index) => {
														return (
															<>
																<>
																	<Card
																		hoverable
																		style={{
																			height: 40,
																		}}
																		onClick={() =>
																			handlePreview(
																				`${config.s3_url}${item.url}`,
																			)
																		}
																		id="timeline-documents-hover"
																	>
																		<Space className="flex-wrap">
																			<img
																				src="/images/appeal-letter-icon.svg"
																				style={{
																					width: '20px',
																				}}
																				alt="appeal letter"
																			/>
																			<p
																				style={{
																					color: 'black',
																					marginBottom: 0,
																					width: 'max-content',
																				}}
																			>
																				Appeal
																				Letter
																			</p>
																		</Space>
																	</Card>
																</>
															</>
														);
													},
												)}
										</Col>
									</Row>
								)}
								<Row
									className="my-4 text-end"
									gutter={[30, 30]}
								>
									<Col xs={24}>
										{item.remarks && (
											<>
												{Array.isArray(item.remarks) ? (
													item.remarks.map(
														(item, index) => {
															return (
																<>
																	{item.name
																		.split(
																			'.',
																		)
																		.includes(
																			'pdf',
																		) ? (
																		<>
																			<Card
																				hoverable
																				style={{
																					height: 40,
																				}}
																				onClick={() =>
																					handlePreview(
																						`${config.s3_url}${item.url}`,
																					)
																				}
																				id="timeline-documents-hover"
																			>
																				<Space className="flex-wrap">
																					<img
																						src="/images/denial-letter-icon.svg"
																						style={{
																							width: '20px',
																						}}
																						alt="denial letter"
																					/>
																					<p
																						style={{
																							color: 'black',
																							marginBottom: 0,
																							width: 'max-content',
																						}}
																					>
																						Denial
																						Letter
																					</p>
																				</Space>
																			</Card>
																		</>
																	) : (
																		<Image
																			style={{
																				borderRadius:
																					'10px',
																			}}
																			height={
																				40
																			}
																			width={
																				40
																			}
																			src={`${config.s3_url}${item.url}`}
																		/>
																	)}
																</>
															);
														},
													)
												) : (
													<Card
														hoverable
														style={{
															height: 40,
														}}
														onClick={() => {
															handleModalPreviewRemarks(
																item.remarks,
																item.revenue_status,
															);
														}}
														id="timeline-documents-hover"
													>
														<Space className="flex-wrap">
															{/* <FileTextOutlined
																style={{
																	fontSize:
																		'20px',
																}}
															/> */}
															<img
																src="/images/denial-letter-icon.svg"
																style={{
																	width: '20px',
																}}
																alt="denial letter"
															/>
															<p
																style={{
																	color: 'black',
																	marginBottom: 0,
																	width: 'max-content',
																}}
															>
																Denial Letter
															</p>
														</Space>
													</Card>
													// <Card
													// 	hoverable
													// 	style={{
													// 		width: 40,
													// 		height: 40,
													// 	}}
													// 	onClick={() => {
													// 		handleModalPreviewRemarks(
													// 			item.remarks,
													// 			item.revenue_status,
													// 		);
													// 	}}
													// 	className="timeline-documents-hover"
													// >
													// 	<FileTextOutlined />
													// </Card>
												)}
											</>
										)}
									</Col>
								</Row>
							</div>
						</>
					);
				} else {
					itemClassName = 'success';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-approved.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col span={12}>
									<p>{item.revenue_status}</p>
								</Col>
								<Col span={12} className="text-end">
									<Tag
										className="tagSuccess"
										icon={
											<CheckCircleOutlined
												style={{
													fontSize: '12px',
													lineHeight: '20px',
												}}
											/>
										}
									>
										Approved
									</Tag>
								</Col>
							</Row>
						</>
					);
				}
			} else {
				if (item.appeal_status == 'Sent') {
					itemClassName = 'inprogress';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-success.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col span={10}>
									<p>{item.revenue_status}</p>
								</Col>
								<Col span={14} className="text-end">
									{item.days_ago && (
										<Tag
											color={getTagColor(
												item.filing_status,
											)}
											className="tagSuccess"
											icon={
												<ClockCircleOutlined
													style={{
														fontSize: '12px',
														lineHeight: '20px',
													}}
												/>
											}
										>
											{' '}
											{item.filing_status !==
											'Untimely' ? (
												<>
													{`Awaiting Payer Response.`}{' '}
													<br />
													{item.days_left}{' '}
													{item.timeline_type
														? toTitleCase(
																item.timeline_type,
														  )
														: item.timeline_type}{' '}
													{`Until Overdue`}
												</>
											) : (
												'Awaiting Payer Response. Timeline Overdue.'
											)}
										</Tag>
									)}
								</Col>
							</Row>
							<Row>
								<Col xxl={20} xs={24} md={16} lg={24} xl={20}>
									<div className="timeLineButton d-flex flex-direction-row align-items-center">
										{checkModulePermissions(
											'ma-plan-appeals-processing',
										).sub_modules.includes(
											'prepare-appeal-letter',
										) ||
										checkModulePermissions(
											'case-filing',
										).sub_modules.includes(
											'prepare-appeal-letter',
										) ? (
											<>
												<Button
													type="primary"
													onClick={() => {
														setRevenueCycleStage(
															revenueStatusToStage(
																item.revenue_status,
															),
														);
														setRevenueCycleStatus(
															'',
														);
														setUpdateModalOpen([
															true,
															'',
														]);
														setRevenueCycleFormData(
															{
																id: id,
																reimbursed_at:
																	'',
																appeal_status:
																	'',
																claim_category:
																	'',
																claim_amount:
																	null,
																status: '',
																revenue_cycle_stage:
																	'',
																is_claim_amount_settled:
																	null,
																appeal_status_updated_at:
																	'',
																revenue_cycle_updates:
																	{
																		revenue_cycle_status:
																			'',
																		appeal_status:
																			'',
																		remarks:
																			'',
																		url: [],
																	},
															},
														);
														setFileList([]);
														setUpdatedFileList([]);
														setSelectedOption('');
													}}
												>
													Update Status
												</Button>
												<Divider
													type="vertical"
													style={{
														borderColor: '#bebfd0',
														fontSize: 'large',
													}}
												/>
											</>
										) : (
											<></>
										)}
										<small
											style={{
												fontSize: '10px',
												color: '#7C7C7C',
											}}
										>
											{item.days_ago
												? toTitleCase(item.days_ago)
												: item.days_ago}{' '}
										</small>
									</div>

									<Dropdown
										size="large"
										overlayStyle={{
											left: '1155px',
										}}
										menu={{
											items,
										}}
										trigger={['click']}
									>
										<a
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
											onClick={(e) => e.preventDefault()}
										>
											<Space>
												<img
													src="/images/dots-icons.svg"
													style={{
														position: 'absolute',
														top: '60%',
														transform:
															'translateY(-50%)',
														right: '-30px',
														width: '20px',
														height: '20px',
													}}
													alt="fax"
												/>
											</Space>
										</a>
									</Dropdown>

									<Modal
										title="Send To"
										open={isShareModal}
										onOk={handleShareOk}
										onCancel={handleShareCancel}
										cancelButtonProps={{ hidden: true }}
										okButtonProps={{ hidden: true }}
										width={'455px'}
									>
										{displayFaxUpload ? (
											<div className="mt-3">
												<Dragger showUploadList={false}>
													<img
														src="/images/file-upload 1.svg"
														style={{
															marginBottom: '8px',
														}}
														alt="file upload"
													/>
													<p
														style={{
															color: '#ACACAC',
															fontFamily:
																'Poppins',
															fontSize: '14px',
															fontStyle: 'normal',
															fontWeight: '500',
															lineHeight: '20px',
															letterSpacing:
																'0.28px',
														}}
													>
														Upload Acknowledgement
													</p>
												</Dragger>
												<XForm.Item className="text-right mt-3">
													<XShareButton
														style={{
															background:
																'#212226',
														}}
														onClick={() => {
															// setSuccessModal(true);
															//!Remove this state on funtion call
															setLetterDownloaded(
																false,
															);
															setDisplayEmailContent(
																false,
															);
															setDisplayFaxUpload(
																false,
															);
															setIsShareModal(
																false,
															);
														}}
													>
														Send
													</XShareButton>
												</XForm.Item>
											</div>
										) : (
											''
										)}
										{displayEmailContent &&
										letterDownloaded ? (
											<div className="mt-3">
												<p>
													The letter has been
													downloaded. Please ensure
													that you do not overlook
													attaching the document in
													the email you are about to
													send.
												</p>
												<XForm.Item className="text-right mt-3">
													<XShareButton
														style={{
															background:
																'#212226',
														}}
														onClick={() => {
															handleEmailSend();
															setIsShareModal(
																false,
															);
														}}
													>
														Send Email
													</XShareButton>
												</XForm.Item>
											</div>
										) : (
											''
										)}
									</Modal>
								</Col>
							</Row>
						</>
					);
				} else if (item.appeal_status === null) {
					itemClassName = 'pending';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-success.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col xs={13} md={10}>
									<p>{item.revenue_status}</p>
								</Col>
								<Col xs={11} md={14} className="text-end">
									{item.days_left && (
										<Tag
											color={getTagColor(
												item.filing_status,
											)}
											className="tagSuccess"
											icon={
												<ClockCircleOutlined
													style={{
														fontSize: '12px',
														lineHeight: '20px',
													}}
												/>
											}
										>
											{item.filing_status !==
											'Untimely' ? (
												<>
													{item.days_left}{' '}
													{item.timeline_type
														? toTitleCase(
																item.timeline_type,
														  )
														: item.timeline_type}{' '}
													{`Left To Respond`}
												</>
											) : (
												'Awaiting Payer Response. Timeline Overdue.'
											)}
										</Tag>
									)}
								</Col>
							</Row>
							<Row>
								{checkModulePermissions(
									'ma-plan-appeals-processing',
								).sub_modules.includes(
									'prepare-appeal-letter',
								) ||
								checkModulePermissions(
									'case-filing',
								).sub_modules.includes(
									'prepare-appeal-letter',
								) ||
								checkModulePermissions(
									'appeal-letter',
								).sub_modules.includes('verify-coding-team') ? (
									<Col span={12}>
										<div className="timeLineButton">
											<Button
												// disabled={prepareButtonDisabled}
												type="primary"
												onClick={() => {
													setTogglePrepareLetter(
														true,
													);
													setFlag(false);
													if (
														patientData?.appeal_letter_status ===
														'md_advisor_sent'
													)
														setApproveModalOpen(
															true,
														);
												}}
											>
												{prepareButtonText()}
											</Button>
										</div>
									</Col>
								) : (
									<></>
								)}
							</Row>
						</>
					);
				} else if (item.appeal_status == 'Approved') {
					itemClassName = 'success';
					itemDot = (
						<img
							alt=""
							className="img-fluid"
							src="/images/icon-approved.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col span={12}>
									<p>{item.revenue_status}</p>
								</Col>
								<Col span={12} className="text-end">
									<Tag
										className="tagSuccess"
										icon={
											<CheckCircleOutlined
												style={{
													fontSize: '12px',
													lineHeight: '20px',
												}}
											/>
										}
									>
										Approved
									</Tag>
								</Col>
							</Row>
						</>
					);
				} else if (item.appeal_status == 'Pending') {
					itemClassName = 'pending';
					itemDot = (
						<img
							class="img-fluid"
							alt=""
							src="/images/icon-pending.svg"
						/>
					);
					itemChildren = (
						<>
							<Row>
								<Col span={12}>
									<p>{item.revenue_status}</p>
								</Col>
							</Row>
						</>
					);
				}
			}

			// Return generated timeline item
			return {
				key: index.toString(), // Provide a unique key
				className: itemClassName,
				dot: itemDot,
				children: itemChildren,
			};
		});
	};

	return (
		<div className="scrollAfix">
			{case_status == 'allocated' ? (
				<>
					<h6
					// style={{color: "#cd4949"}}
					>
						Case Analysis Awaited ...
					</h6>
				</>
			) : medicareCoverage == 'Yes' ? (
				<>
					<Timeline items={generateTimelineItems()} />
					{/* Modal for preview */}
					<Modal
						open={pdfPreviewOpen}
						title="Preview"
						footer={null}
						onCancel={() => setPdfPreviewOpen(false)}
						width={pdfWidth} // Set the width of the modal dynamically
						// Other modal props...
					>
						<div>
							<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
								<div style={{ height: '750px' }}>
									<Viewer fileUrl={previewImage} />
								</div>
							</Worker>
							{/* <Document
								file={previewImage}
								onLoadSuccess={onDocumentLoadSuccess}
								onLoadError={onDocumentLoadError}
							>
								{Array.from(
									new Array(numPages),
									(el, index) => (
										<Page
											key={`page_${index + 1}`}
											pageNumber={index + 1}
											onLoadSuccess={(page) =>
												updateModalWidth(page.width)
											}
											renderTextLayer={false}
											renderAnnotationLayer={false}
										/>
									),
								)}
							</Document> */}
							{/* <p>
								Page {pageNumber} of {numPages}
							</p> */}
						</div>
					</Modal>
					<Modal
						title={`Denial Reason for ${statusName}`}
						open={isModalOpen}
						onOk={handleOk}
						onCancel={handleCancel}
						okButtonProps={{
							style: {
								display: 'none',
							},
						}}
						cancelButtonProps={{ style: { display: 'none' } }}
					>
						<p>{documentText}</p>
					</Modal>
				</>
			) : (
				<>
					<h6 style={{ color: '#cd4949' }}>
						This case is not covered under Medicare Coverage
					</h6>
				</>
			)}
		</div>
	);
};

export default CustomTimeline;
