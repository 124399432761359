import moment from 'moment';

export const isLogin = () => {
	let userAccessToken = localStorage.getItem('accessToken');
	if (userAccessToken) return true;
	return false;
};

export const getRefreshToken = () => {
	return localStorage.getItem('refreshToken') || null;
};

export const getExpiresAt = () => {
	return localStorage.getItem('expiresAt') || null;
};

export const getUserToken = () => {
	return localStorage.getItem('accessToken') || null;
};

export const getUserId = () => {
	return localStorage.getItem('user_id') || null;
};

export const getUserName = () => {
	return toTitleCase(localStorage.getItem('username')) || '';
};

export const getUserProfile = () => {
	return localStorage.getItem('profile') || '';
};

export const getDate = () => {
	const today = new Date();

	// Define arrays of day names and month names for formatting
	const dayNames = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	// Get the day of the week (0 = Sunday, 1 = Monday, ...)
	const dayOfWeek = today.getDay();
	// Get the month (0 = January, 1 = February, ...)
	const month = today.getMonth();
	// Get the day of the month
	const dayOfMonth = today.getDate();
	// Get the year
	const year = today.getFullYear();

	// Format the date as "Day, Month DD, YYYY"
	const formattedDate = `${dayNames[dayOfWeek]}, ${monthNames[month]} ${dayOfMonth}, ${year}`;

	return formattedDate;
};

export const toTitleCase = (str) => {
	// also replace _ by space
	if (str) {
		str = str.replace(/_/g, ' ');
		return str.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	} else return;
};

export const checkModulePermissions = (module) => {
	let permissions_response = {
		authorized: false,
		message: 'Not Authroized',
		sub_modules: [],
	};
	let permissions = JSON.parse(localStorage.getItem('permissions')) || [];
	let check_module_exists = permissions.filter((item) => {
		if (item.module.module_slug === module) {
			return item;
		}
	});

	if (check_module_exists.length > 0) {
		let sub_modules = [];

		check_module_exists[0].sub_modules.map((item) => {
			sub_modules.push(item.sub_module_slug);
		});

		permissions_response = {
			...permissions_response,
			authorized: true,
			message: 'Authroized',
			sub_modules,
		};
	}

	return permissions_response;
};

export const truncateString = (text, maxLength) => {
	if (!maxLength) {
		const words = text.split('_');
		const capitalizedText = words
			.map(
				(word) =>
					word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
			)
			.join(' ');
		return capitalizedText;
	}
	if (text && text.length <= maxLength) {
		return text;
	} else return `${text && text.substring(0, maxLength)}...`;
};

export const urlPagination = (filters, filterKey, filterValue) => {
	const queryParams = new URLSearchParams();

	// Append each filter to the query string
	Object.entries(filters).forEach(([key, value]) => {
		queryParams.append(key, value);
	});
	//Setting page filters: page size, sort, sort direction and limit in the query string
	queryParams.set('page', 1);
	queryParams.set(filterKey, filterValue);

	const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
	window.history.pushState({}, '', newUrl);
};

export const getFileNameFromDocPath = (doc_path) => {
	return doc_path?.split('/').pop();
};

export const disableFutureDates = (current) => {
	return current && current > moment().endOf('day');
};
